<template>
  <div class="container">
    <div class="issue-body">
      <div class="issue-block">
        <h3 class="issue-title">您有什么需求可以在这里发布哦！</h3>
        <ul class="demand-list">
          <li class="dl-item" v-for="(item,index) in demand_list" :key="index">
            <div class="dl-icon">
              <div class="icon-box">
                <el-image
                  :src="item.icon"
                  fit="scale-down"
                ></el-image>
              </div>
              <span class="icon-text">{{item.name}}</span>
            </div>
            <div class="dl-descriptions">
              <p class="fake-p" v-for="(citem,cindex) in item.text" :key="cindex">{{citem}}</p>
            </div>
            <div class="dl-btn">
              <router-link :to="item.path">
                <el-button class="fake-btn">
                  <span>立即发布</span>
                </el-button>
              </router-link>
            </div>
          </li>
        </ul>
      </div>

      <div class="issue-block">
        <h3 class="issue-title">快来这发布一些优质资源吧！</h3>
        <ul class="resource-list">
          <li class="rl-item" v-for="(item,index) in resource_list" :key="index">
            <div class="rl-icon">
              <el-image
                :src="item.icon"
                fit="scale-down"
              ></el-image>
            </div>
            <div class="rl-info">
              <h3 class="rl-name">{{item.name}}</h3>
              <div class="rl-descriptions">
                <p class="fake-p" v-for="(citem,cindex) in item.text" :key="cindex">{{citem}}</p>
              </div>
              <div class="dl-btn">
                <router-link :to="item.path">
                  <el-button class="fake-btn">
                    <span>立即发布</span>
                  </el-button>
                </router-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      ossUrl: this.GLOBAL.ossUrl,
      demand_list: [], // 需求
      resource_list: [], // 资源(活动、拼团/秒杀、场地)
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 初始化页面数据
     */
    initPageDataFun() {
      this.createDemandListFun(); // 生成需求列表
      this.createResourceListFun(); // 生成资源列表
      // // 获取账号信息
      // this.getUserInfoFun().then(res => {
      //   // 生成资源列表
      //   this.createResourceListFun(res.identity);
      // });
    },
    /**
     * 生成需求列表
     */
    createDemandListFun() {
      let ossUrl = this.ossUrl;
      let data = [{
        icon: ossUrl + '/images/issue-img/issue-icon (1).png',
        name: '团建需求',
        text: ['可以根据您的需要来发布您活动所需','的各类要求，可开启竞标模式来','获取更多的优秀方案！',],
        // path: '/login?id=2&time='+ Date.now(),
        path: '/issue/common-form?id=11',
      },{
        icon: ossUrl + '/images/issue-img/issue-icon (6).png',
        name: '场地需求',
        text: ['根据您心目中所需要的场地来填写发','布，让您摆脱在众多场地中挑选','的苦恼！',],
        path: '/issue/common-form?id=12',
      },{
        icon: ossUrl + '/images/issue-img/issue-icon (7).png',
        name: '教练需求',
        text: ['通过发布您活动所需的教练，让您的','需求迅速的传播，教练主动联系','您，让您有更多优质选择！',],
        path: '/issue/common-form?id=13',
      }];
      this.demand_list = data;
    },
    /**
     * 生成资源列表
     */
    createResourceListFun(type) {
      let ossUrl = this.ossUrl;
      let data = [{
        icon: ossUrl + '/images/issue-img/issue-icon (8).png',
        name: '机构入驻',
        text: ['优质的团建或其他类型的公司，入驻到平台，让您的机构曝光率更大，更好的获取优质资源！'],
        path: '/issue/common-form?id=1',
      },{
        icon: ossUrl + '/images/issue-img/issue-icon (4).png',
        name: '场地入驻',
        text: ['各类场地入驻，尽显优质场地设施，让您 的场地让更多的人知晓，带来更多的收益！'],
        path: '/issue/common-form?id=2',
      },{
        icon: ossUrl + '/images/issue-img/issue-icon (5).png',
        name: '活动',
        text: ['周末爬山、冬夏令营、户外团建、亲子互动等活动，轻松设置发布，轻松搞定活动宣传！'],
        path: '/issue/common-form?id=3',
      },{
        icon: ossUrl + '/images/issue-img/issue-icon (9).png',
        name: '租赁',
        text: ['服装道具、循环利用，节省用户的开支，提升租赁商户的商品价值，实现资源利用最大化。'],
        path: '/issue/common-form?id=4',
      },{
        icon: ossUrl + '/images/issue-img/issue-icon (3).png',
        name: '拼团',
        text: ['通过“拼团”的方式，让参与者去邀请身边好友拼团购买，实现活动的迅速裂变，迅速扩展活动人群！'],
        path: '/issue/common-form?id=5',
      },{
        icon: ossUrl + '/images/issue-img/issue-icon (2).png',
        name: '秒杀',
        text: ['通过“秒杀”的方式，让活动凭借价格优势提升活动竞争力，吸引更多的人报名参加！'],
        path: '/issue/common-form?id=6',
      }];
      this.resource_list = data;
    },
    /**
     * 获取账号信息
     */
    getUserInfoFun() {
      return new Promise((resolve) => {
        this.myRequest({
          url: '/api/user/userinfostatus',
        }).then((res) => {
          console.log('获取账号信息 res == ',res)
          let resData = res.data.data;
          this.userInfo = resData;
          if(typeof this.userInfo == 'object') {
            this.userInfo.loading = true;
          }
          resolve(resData);
        })
      })
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    this.initPageDataFun(); // 初始化页面数据
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
}
.issue-body {
  box-sizing: border-box;
  padding: 0 $theme-widht-whiteedge;
  // width: $theme-view-widht;
  max-width: $theme-view-widht;
  min-width: $theme-view-min-widht;
  margin: 0 auto;
  .issue-block {
    width: 1190px;
    margin: 80px auto;
  }
  .issue-title {
    margin-bottom: 50px;
    text-align: center;
    font-weight: 400;
    font-size: 30px;
    color: #444343;
  }
  .demand-list {
    display: flex;
    justify-content: space-between;
    .dl-item {
      box-sizing: border-box;
      width: 350px;
      height: 420px;
      padding-top: 50px;
      text-align: center;
      border-radius: 4px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0 2px 8px -3px rgba(0,0,0,0.2);
      .dl-icon {
        .icon-box {
          width: 80px;
          height: 80px;
          margin: 0 auto;
        }
        .icon-text {
          font-size: 20px;
          color: #444343;
        }
      }
      .dl-descriptions {
        margin: 36px 0;
        font-size: 14px;
        color: #787878;
        .fake-p {
          margin: 3px 0;
        }
      }
      .dl-btn {
        width: 100px;
        margin: 10px auto;
        &::v-deep {
          .fake-btn {
            width: 100%;
            height: 32px;
            padding: 0;
            font-size: 14px;
            color: $common-number1-color;
            border: 1px solid $common-number1-color;
            background: #fff;
            outline: none;
            &.el-button:hover,&.el-button:focus,&.el-button:active {
              color: none;
              border-color: none;
              background-color: none;
            }
            &.el-button:active {
              color: $active-default-color;
              border-color: $active-default-color;
            }
          }
        }
      }
    }
  }
  .resource-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .rl-item {
      box-sizing: border-box;
      display: flex;
      width: 560px;
      height: 240px;
      padding: 0 30px;
      padding-top: 50px;
      margin-top: 50px;
      border-radius: 4px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0 2px 8px -3px rgba(0,0,0,0.2);
      &:nth-child(1),&:nth-child(2) {
        margin-top: 0;
      }
      .rl-icon {
        width: 80px;
        height: 80px;
        margin-right: 10px;
      }
      .rl-info {
        flex: 1;
        min-width: 0;
        .rl-name {
          font-size: 20px;
          color: #444343;
        }
        .rl-descriptions {
          margin: 10px 0 30px;
          font-size: 14px;
          color: #787878;
          .fake-p {
            line-height: 1.6;
          }
        }
        .dl-btn {
          width: 100px;
          &::v-deep {
            .fake-btn {
              width: 100%;
              height: 32px;
              padding: 0;
              font-size: 14px;
              color: $common-number1-color;
              border: 1px solid $common-number1-color;
              background: #fff;
              outline: none;
              &.el-button:hover,&.el-button:focus,&.el-button:active {
                color: none;
                border-color: none;
                background-color: none;
              }
              &.el-button:active {
                color: $active-default-color;
                border-color: $active-default-color;
              }
            }
          }
        }
      }
    }
  }
}
</style>
